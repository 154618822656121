<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.create_estate") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="createEstate"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);

    const waiting = computed(() => store.state.company.waiting);

    const formElements = ref(null);

    const setFormElements = () => {
      // Taloyhtiö tunnus
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: "",
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "address",
          label: "address",
          rules: ["isRequired"],
          type: "text",
          value: "",
          variable: "address",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "postal-code",
          label: "postal_code",
          rules: ["isRequired", "isPostalCode"],
          type: "text",
          value: "",
          variable: "postalCode",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "city",
          label: "city",
          rules: ["isRequired"],
          type: "text",
          value: "",
          variable: "city",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "business-id",
          label: "business_id",
          rules: ["isRequired", "isBusinessId"],
          type: "text",
          value: "",
          variable: "businessId",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "identifier",
          label: "identifier",
          rules: [],
          type: "text",
          value: "",
          variable: "identifier",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "datepicker",
          id: "energy-certificate-date",
          label: "energy_certificate",
          rules: ["isRequired"],
          value: getISODate(new Date()),
          variable: "energyCertificate",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const createEstate = (data) => {
      data.companyId = company.value.id;
      store.dispatch("estate/createEstate", data);
    };

    onMounted(() => {
      if (company.value.id) {
        setFormElements();
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      t,
      createEstate,
    };
  },
};
</script>
